/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2025.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { isEmptyString } from "src/app/utils/typeguards";
import { getEnvValue } from "src/app/utils/constants/constants";
import { hotjar } from "react-hotjar";

type Props = ReturnType<typeof mapStateToProps>;

function HotJar({ isAdminPanel }: Props) {
	if (!isAdminPanel && !isEmptyString(getEnvValue("REACT_APP_HOTJAR_ID")) && !isEmptyString(getEnvValue("REACT_APP_HOTJAR_SNIPPET_VERSION"))) {
		hotjar.initialize(
			{
				id: +getEnvValue("REACT_APP_HOTJAR_ID"),
				sv: +getEnvValue("REACT_APP_HOTJAR_SNIPPET_VERSION"),
			},
		);

	}

	return null;
}

const mapStateToProps = (state: RootState) => ({
	isAdminPanel: state.router.location?.pathname?.startsWith("/panel"),
});

export default connect(mapStateToProps)(HotJar);
