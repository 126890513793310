/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormHookReturnType } from "src/app/types/ui/form.types";
import { PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import { PurchasingProcessFormStep } from "src/app/types/api/reservation.types";
import { purchasingProcessFormStepAvailableRoutesDictionary, purchasingProcessFormStepDictionary } from "src/app/utils/constants/dictionaries";
import classNames from "classnames";
import logo_short from "src/assets/images/logo_short.png";
import { SlArrowRight } from "react-icons/sl";
import { useReservationBreadcrumbHeaderHeight } from "src/app/utils/hooks/useReservationBreadcrumbHeaderHeight";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { KAKADU_LANDING_LINK } from "src/app/utils/constants/constants";

type Props = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
}

function ReservationBreadcrumbsHeader(props: Props) {

	const {
		form: {
			form: {
				step,
			},
			handleChange,
		},
	} = props;

	const { height: headerHeight } = useReservationBreadcrumbHeaderHeight();

	const onRouteClick = (clickedStep: PurchasingProcessFormStep) => {
		if (!purchasingProcessFormStepAvailableRoutesDictionary[ step.value ].includes(clickedStep)) return;
		handleChange("step", clickedStep);
	}

	return (
		<div
			className="flex flex-col justify-between bg-myPrimary-purple-500 w-full"
			style={ {
				height: headerHeight,
				minHeight: headerHeight,
				maxHeight: headerHeight,
			} }>
			<div className="flex justify-center items-center relative w-full h-full">
				<Link to={ KAKADU_LANDING_LINK } target="_blank">
					<img
						src={ logo_short }
						alt="logo_short"
						className={ classNames(
							"h-8",
							"md:h-11",
						) }
					/>
				</Link>
				<div className="absolute right-2 top-1/2 -translate-y-1/2">
					{/* todo: add cart-logo */}
				</div>
			</div>
			<div className={ classNames(
				"flex justify-center items-center border-t-[1px] border-t-myPrimary-orange-500",
				"gap-1 py-3",
				"sm:gap-3",
				"md:gap-5 md:py-4",
			) }>
				{
					Object.values(PurchasingProcessFormStep)
						  .filter((stepEnum) => stepEnum !== PurchasingProcessFormStep.FAILED && stepEnum !== PurchasingProcessFormStep.PROCESSING)
						  .map((stepEnum, index, { length }) =>
							  <Fragment key={ stepEnum }>
								  <div
									  key={ stepEnum }
									  className={ classNames(
										  "text-myPrimary-orange-500 uppercase",
										  "text-[10px]",
										  "xxs:text-xs",
										  "md:text-sm md:tracking-wider",
										  "lg:text-base lg:tracking-[0.5rem]",
										  { "font-[900]": stepEnum === step.value },
										  { "cursor-pointer": purchasingProcessFormStepAvailableRoutesDictionary[ step.value ].includes(stepEnum) },
									  ) }
									  onClick={ () => onRouteClick(stepEnum) }
								  >
									  { purchasingProcessFormStepDictionary[ stepEnum ] }
								  </div>
								  {
									  index !== length - 1 &&
                                      <SlArrowRight className={ classNames(
										  "text-myPrimary-orange-500",
										  "h-3 w-3",
										  "sm:h-4 sm:w-4",
										  "md:h-5 md:w-5",
									  ) }/>
								  }
							  </Fragment>,
						  )
				}
			</div>
		</div>
	);
}

export default ReservationBreadcrumbsHeader;
