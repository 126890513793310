/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Link, matchPath, NavLink, Outlet, useLocation } from "react-router-dom";
import classNames from "classnames";
import logo_short from "src/assets/images/logo_short.png";
import useReservationBreadcrumbHeaderHeight from "src/app/utils/hooks/useReservationBreadcrumbHeaderHeight";
import { SlArrowRight } from "react-icons/sl";
import { defaultView, KAKADU_LANDING_LINK } from "src/app/utils/constants/constants";

function VoucherLayout() {

	const { height: headerHeight } = useReservationBreadcrumbHeaderHeight();

	const location = useLocation();

	return (
		<div>
			<main className="w-full">
				<div className="bg-myPrimary-gray-300 min-h-screen h-full">
					<div className="flex flex-col h-full bg-myPrimary-gray-300">
						<div
							className="flex flex-col justify-between bg-myPrimary-purple-500 w-full"
							style={ {
								height: headerHeight,
								minHeight: headerHeight,
								maxHeight: headerHeight,
							} }
						>
							<div className="flex justify-center items-center relative w-full h-full">
								<Link to={ KAKADU_LANDING_LINK } target="_blank">
									<img
										src={ logo_short }
										alt="logo_short"
										className={ classNames(
											"h-8",
											"md:h-11",
										) }
									/>
								</Link>
							</div>
							<div className={ classNames(
								"flex justify-center items-center border-t-[1px] border-t-myPrimary-orange-500",
								"gap-1 py-3",
								"sm:gap-3",
								"md:gap-5 md:py-4",
							) }>
								<NavLink to={ defaultView }>
									{
										({ isActive }) =>
											<div
												className={ classNames(
													"text-myPrimary-orange-500 uppercase",
													"text-[10px]",
													"xxs:text-xs",
													"md:text-sm md:tracking-wider",
													"lg:text-base lg:tracking-[0.5rem]",
													{ " font-[900]": isActive },
												) }
											>
												Pokoje
											</div>
									}
								</NavLink>
								<SlArrowRight className={ classNames(
									"text-myPrimary-orange-500",
									"h-3 w-3",
									"sm:h-4 sm:w-4",
									"md:h-5 md:w-5",
								) }/>
								<NavLink to={ "/voucher" }>
									{
										({ isActive }) =>
											<div
												className={ classNames(
													"text-myPrimary-orange-500 uppercase",
													"text-[10px]",
													"xxs:text-xs",
													"md:text-sm md:tracking-wider",
													"lg:text-base lg:tracking-[0.5rem]",
													{ " font-[900]": isActive },
												) }
											>
												Zakup vouchera
											</div>
									}
								</NavLink>
								<SlArrowRight className={ classNames(
									"text-myPrimary-orange-500",
									"h-3 w-3",
									"sm:h-4 sm:w-4",
									"md:h-5 md:w-5",
								) }/>
								<div
									className={ classNames(
										"text-myPrimary-orange-500 uppercase",
										"text-[10px]",
										"xxs:text-xs",
										"md:text-sm md:tracking-wider",
										"lg:text-base lg:tracking-[0.5rem]",
										{ " font-[900]": matchPath("confirmation-voucher", location.pathname) },
									) }
								>
									Potwierdzenie
								</div>
							</div>
						</div>
						<div className={ classNames(
							"py-4 h-full",
							"px-4",
							"lg:px-14",
						) }>
							<div className="max-w-[1440px] mx-auto">
								<Outlet context={ {} }/>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
}

export default VoucherLayout;