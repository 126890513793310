/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import classNames from "classnames";
import { DateTime } from "luxon";
import { CartItem, CartItemType } from "src/app/types/api/reservation.types";
import { isNotNull } from "src/app/utils/typeguards";
import Avatar from "src/app/components/Utils/Avatar.component";
import PlaceholderImage from "src/app/components/PurchasingProcess/util/PlaceholderImage.component";
import React from "react";
import { LocaleFromISO } from "src/app/utils/luxon";

type Props = {
	cartItem: CartItem & {
		type: CartItemType.SESSION
		date: string
		startTime: string
		endTime: string
	}
	className?: string
}

function CartItemSession(props: Props ) {

	const {
		cartItem,
		className = "",
	} = props;

	return (
		<div className={ classNames(
			"flex gap-5 border-[1px] border-gray-200 p-3 flex-wrap",
			"flex-col",
			"xs:flex-row",
			className,
			) }>
			<div className="flex gap-5">
				<div className="flex flex-col items-center gap-2">
					<div className={ classNames(
						"flex justify-center",
						"h-16 w-16 min-h-[64px] min-w-[64px]",
						"xxs:h-24 xxs:w-24 xxs:min-h-[96px] xxs:min-w-[96px]",
					) }>
						{
							isNotNull(cartItem?.image)
								?
								<Avatar
									img={ cartItem?.image?.thumb }
									className="[&>div>img]:h-16 [&>div>img]:min-h-[64px] [&>div>img]:w-16 [&>div>img]:min-w-[64px]"
								/>
								:
								<PlaceholderImage className="h-full w-full"/>
						}
					</div>
				</div>
				<div className="flex flex-col">
					<div className={ classNames(
						"uppercase font-[900]",
						"text-xs",
						"xs:text-sm",
						"sm:text-base",
					) }>
						{ cartItem.title }
					</div>
					<div className="flex items-center text-xs font-bold gap-1 flex-wrap whitespace-nowrap">
						<span className="text-myPrimary-purple-500">
							{ `${ DateTime.fromISO(cartItem.date).setLocale("pl").toFormat("DDDD") }, ` }
						</span>
						<span className="text-myPrimary-orange-500">
							{ `${ LocaleFromISO(cartItem.startTime).toLocaleString(DateTime.TIME_SIMPLE) } - ${ LocaleFromISO(cartItem.endTime).toLocaleString(DateTime.TIME_SIMPLE) }` }
						</span>
					</div>
					<ul>
						{
							cartItem.bulletPoints.map((bulletPoint, index) =>
								<li
									key={ index }
									className="text-xs text-myPrimary-purple-500 font-light"
								>
									{ bulletPoint }
								</li>
							)
						}
					</ul>
				</div>
			</div>
			<div className="ml-auto flex">
				<div className="font-extrabold whitespace-nowrap">
					{ `${ cartItem.price } ZŁ` }
				</div>
			</div>
		</div>
	);
}

export default CartItemSession;
