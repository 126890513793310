/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { AdminAvailability, CreateReservationPayload } from "src/app/types/api/reservation.types";
import { Nullable } from "src/app/types/util.types";
import { SimpleRoom } from "src/app/types/api/room.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { Product } from "src/app/types/api/product.types";
import { DiscountCode } from "src/app/types/api/discountCode.types";
import { CreateRoomReservationForm, getAllProductsCount } from "src/app/utils/constants/roomReservation.form";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { roomReservationFormActions } from "src/app/store/features/form/form.actions";
import React, { useEffect, useRef, useState } from "react";
import { createFormField } from "src/app/utils/forms";
import { Button, Modal, Tabs, TabsRef } from "flowbite-react";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import Select from "src/app/components/Form/Select.component";
import { DateTime } from "luxon";
import { SimpleVenue } from "src/app/types/api/venue.types";
import CreateReservationModalInner from "src/app/components/Room/Reservation/CreateReservationModal/CreateReservationModalInner.component";
import { uiCalculateAdminRoomReservationPrice, uiFetchAdminRoomAvailabilities } from "src/app/store/features/ui/reservation/ui.reservation.actions";
import { getAdminRoomAvailabilities } from "src/app/store/features/reservation/reservation.selectors";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { LocaleFromISO } from "src/app/utils/luxon";
import DatePicker from "src/app/components/Form/DatePicker.component";
import { MAX_FUTURE_MONTHS } from "src/app/utils/constants/constants";
import { FormItem } from "src/app/types/ui/form.types";
import { SimpleVoucher } from "src/app/types/api/voucher.types";

type ComponentProps = {
	isOpen: boolean
	availability?: Nullable<AdminAvailability>
	handleClose: () => void
	products: Product[]
	discountCodes: DiscountCode[]
	vouchers: SimpleVoucher[]
	onCreate: (payload: CreateReservationPayload) => void
	date?: DateTime<boolean>
	rooms: SimpleRoom[]
	venues: SimpleVenue[]
	room?: SimpleRoom
	venue?: SimpleVenue
}

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& ComponentProps;

function CreateReservationModal(props: Props) {

	const {
		isOpen,
		availability,
		handleClose,
		products,
		discountCodes,
		vouchers,
		onCreate,
		calculateReservationPrice,
		date,
		rooms,
		room,
		venues,
		venue,
		isCreating,
		getAdminRoomAvailabilities,
		fetchAdminRoomAvailabilities,
		isCalculatingPrice,
	} = props;

	const [ activeTab, setActiveTab ] = useState(0);

	const tabsRef = useRef<TabsRef>(null);

	const handleCloseWithTabSet = () => {
		setActiveTab(0);
		handleClose();
	};

	const _handleSubmit = (values: CreateRoomReservationForm) => {
		if (
			isNull(values.date) ||
			isNull(values.startDate) ||
			isNull(values.endDate) ||
			isNull(values.room)
		) {
			return;
		}

		if (values.isForCompany) {
			onCreate({
				date: values.date,
				roomId: values.room.id,
				startDate: values.startDate,
				endDate: values.endDate,
				people: +values.people,
				name: values.name,
				surname: values.surname,
				email: values.email,
				phone: values.formattedPhone,
				reservingPerson: values.reservingPerson,
				comment: values.comment,
				clientComment: values.clientComment,
				isMarketingConsent: values.isMarketingConsent,
				discountCode: values.discountCode ?? undefined,
				products: values.products,
				isForCompany: true,
				companyName: values.companyName,
				companyNip: values.companyNip,
				companyAddress: values.companyAddress,
				companyPostCode: values.companyPostCode,
				companyCity: values.companyCity,
			});
		} else {
			onCreate({
				date: values.date,
				roomId: values.room.id,
				startDate: values.startDate,
				endDate: values.endDate,
				people: +values.people,
				name: values.name,
				surname: values.surname,
				email: values.email,
				phone: values.formattedPhone,
				reservingPerson: values.reservingPerson,
				comment: values.comment,
				clientComment: values.clientComment,
				isMarketingConsent: values.isMarketingConsent,
				discountCode: values.discountCode ?? undefined,
				products: values.products,
				isForCompany: false,
			});
		}
		handleClose();
	};

	const createReservationForm = useReducerForm(
		"roomReservation",
		roomReservationFormActions,
		_handleSubmit,
	);

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = createReservationForm;

	const allProductsCount = getAllProductsCount(form.products.value);

	useEffect(() => {
		if (!isOpen) return;
		setForm({
			date: createFormField(date?.isValid ? LocaleFromISO(date.toISO()).toISO() : null),
			startDate: createFormField(availability?.startTime ?? null),
			endDate: createFormField(availability?.endTime ?? null),
			people: createFormField(""),
			room: createFormField(room ?? null),
			name: createFormField(""),
			surname: createFormField(""),
			email: createFormField(""),
			phone: createFormField(""),
			formattedPhone: createFormField(""),
			reservingPerson: createFormField("", { optional: true }),
			comment: createFormField("", { optional: true }),
			clientComment: createFormField("", { optional: true }),
			isMarketingConsent: createFormField(false),
			products: createFormField(products.map(({ id }) => ({ id, quantity: 0 }))),
			discountCode: createFormField(null),
			isDiscountedPriceUnderflow: createFormField(false),
			isForCompany: createFormField(false),
			companyName: createFormField(""),
			companyNip: createFormField(""),
			companyAddress: createFormField(""),
			companyPostCode: createFormField(""),
			companyCity: createFormField(""),
			venue: createFormField(venue ?? null),
			price: createFormField(availability?.price?.toString() ?? ""),
			discountedPrice: createFormField(null),
		});
		setActiveTab(0);
	}, [ isOpen ]);

	// For bug-fixing purpose
	useEffect(() => {
		if (isCreating) return;
		setActiveTab(0);
	}, [ isCreating ]);

	// Calculating the price
	useEffect(() => {
		if (
			!isOpen ||
			activeTab === 0 ||
			isNull(form.startDate.value) ||
			isNull(form.endDate.value) ||
			isNull(form.room.value)
		) {
			return;
		}

		calculateReservationPrice({
			discountCode: form.discountCode?.value ?? undefined,
			products: form.products.value,
			roomId: form.room.value.id,
			startDate: form.startDate.value,
			endDate: form.endDate.value,
		});
	}, [ form.discountCode.value, allProductsCount, form.startDate.value, form.endDate.value, isOpen ]);

	const _isCalculatingPrice = isCalculatingPrice(isNotNull(form.room.value) ? form.room.value.id : 0);

	const createFormFieldWithAllValues = (formItem: FormItem<Nullable<string>>, newValue: Nullable<Date>): FormItem<Nullable<Date>> => ({
		value: newValue,
		initialValue: new Date(),
		touched: form.date.touched,
		error: form.date.error,
		success: form.date.success,
		disabled: form.date.disabled,
		optional: form.date.optional,
	});

	const disableSecondTab = isNull(form.venue.value) || isNull(form.room.value) || isNull(form.date.value);

	return (
		<Modal
			show={ isOpen || isCreating }
			onClose={ handleCloseWithTabSet }
			size="4xl"
			root={ document.body }
			className="[&>div]:!max-h-[100vh]"
			key={ (isOpen || isCreating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header className="w-full [&>h3]:w-full [&>h3>div>div>button]:w-1/2 [&>h3>div>div>div]:p-0">
				<Tabs
					ref={ tabsRef }
					aria-label="Default tabs"
					className="w-full"
					style="default"
					onActiveTabChange={ tab => setActiveTab(tab) }
				>
					<Tabs.Item active title="Wybierz lokacje oraz pokój"></Tabs.Item>
					<Tabs.Item title="Rezerwacja" disabled={ disableSecondTab }></Tabs.Item>
				</Tabs>
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					{
						activeTab === 0 &&
                        <div className="space-y-2">
                            <DatePicker
                                label="Data"
                                formItem={
									isNotNull(form.date.value) ?
										createFormFieldWithAllValues(form.date, LocaleFromISO(form.date.value).toJSDate()) :
										createFormFieldWithAllValues(form.date, null)
								}
                                onChange={ date => handleChange("date", date.toISOString()) }
                                onBlur={ () => handleBlur("date") }
                                minDate={ DateTime.now().toJSDate() }
                                maxDate={ DateTime.now().plus({ months: MAX_FUTURE_MONTHS }).toJSDate() }
                                showTimeInput={ false }
                            />
                            <Select
                                label="Lokal"
                                options={ venues.map(venue => ({ label: venue.name, value: venue })) }
                                formItem={ form.venue }
                                onChange={ option => {
									handleChange("venue", option?.value ?? null);
									handleChange("room", null);
									handleBlur("venue");
								} }
                                isSearchable={ true }
                                isClearable={ false }
                                compareValue={ (a: Nullable<SimpleVenue>, b: Nullable<SimpleVenue>) => a?.id === b?.id }
                            />
                            <Select<Nullable<SimpleRoom>>
                                label="Pokój"
                                options={ rooms.filter(({ venueId }) => venueId === form.venue.value?.id).map(room => ({ label: room.name, value: room })) }
                                formItem={ form.room }
                                onChange={ option => {
									handleChange("room", option?.value ?? null);
									handleBlur("room");
								} }
                                isSearchable={ true }
                                isClearable={ false }
                                compareValue={ (a: Nullable<SimpleRoom>, b: Nullable<SimpleRoom>) => a?.id === b?.id }
                            />
                        </div>
					}
					{
						(
							activeTab === 1 &&
							isNotNull(form.date.value) &&
							isNotNull(form.room.value) &&
							isNotNull(form.venue.value)
						) &&
                        <CachedThenFreshStrategy
                            request={ () => fetchAdminRoomAvailabilities({
								roomId: isNotNull(form.room.value) ? form.room.value.id : 0,
								date: isNotNull(form.date.value) ? LocaleFromISO(form.date.value).toFormat("yyyy-MM-dd") : "",
							}) }
                            state={ getAdminRoomAvailabilities(form.room.value.id, LocaleFromISO(form.date.value)) }
                            useEffectDependency={ isNotNull(form.date.value) ? LocaleFromISO(form.date.value).toFormat("yyyy-MM-dd") : "" }
                        >
							{
								roomAvailabilities =>
									<CreateReservationModalInner
										availabilities={ roomAvailabilities }
										products={ products }
										discountCodes={ discountCodes }
										vouchers={ vouchers }
										form={ createReservationForm }
										isCalculatingPrice={ _isCalculatingPrice }
									/>
							}
                        </CachedThenFreshStrategy>

					}
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleCloseWithTabSet } color="gray-outline">
						Anuluj
					</Button>
					{
						activeTab === 0 &&
                        <Button
                            type="button"
                            onClick={ () => tabsRef.current?.setActiveTab(1) }
                            disabled={ isNull(form.venue.value) || isNull(form.room.value) }
                        >
                            Dalej
                        </Button>
					}
					{
						activeTab === 1 &&
                        <Button
                            type="submit"
                            disabled={ _isCalculatingPrice }
                            isProcessing={ isCreating }
                        >
                            Stwórz
                        </Button>
					}
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState) => ({
	getAdminRoomAvailabilities: (roomId: number, date: DateTime) => getAdminRoomAvailabilities(state, { roomId, date }),
	isCreating: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_RESERVATION }),
	isCalculatingPrice: (loadableId: number) => didLoadingRecordExist(state, { loadableId, loadableType: LoadableType.CALCULATE_ROOM_RESERVATION_PRICE }),
});

const mapDispatchToProps = {
	calculateReservationPrice: uiCalculateAdminRoomReservationPrice,
	fetchAdminRoomAvailabilities: uiFetchAdminRoomAvailabilities,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReservationModal);
