/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { DateTime } from "luxon";
import { Nullable } from "src/app/types/util.types";
import { VENUE_TIMEZONE } from "src/app/utils/constants/constants";

export const LocaleFromISO = (iso: Nullable<string>) => DateTime.fromISO(iso ?? "").setZone(VENUE_TIMEZONE).setLocale("pl"); // todo: set locale to venue locale

export const getVenueTodayDateTime = () => DateTime.now().setZone(VENUE_TIMEZONE);