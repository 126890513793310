/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import classNames from "classnames";
import { SimpleRoom } from "src/app/types/api/room.types";
import PlaceholderImage from "src/app/components/PurchasingProcess/util/PlaceholderImage.component";
import { isNotNull } from "src/app/utils/typeguards";
import Avatar from "src/app/components/Utils/Avatar.component";
import { CSSProperties } from "react";
import { HiPlus } from "react-icons/hi";

type Props = {
	room: SimpleRoom
	width: number,
	height: number,
	className?: string
	onClick: () => void
	disableClick?: boolean
	style?: CSSProperties
}

function RoomPreviewAdmin(props: Props) {

	const {
		room: {
			image,
			name,
			maxPeople,
		},
		onClick,
		className = "",
		width,
		height,
		disableClick = false,
		style,
	} = props;

	return (
		<div
			onClick={ !disableClick ? onClick : undefined }
			className={ classNames(
				"relative flex items-end bg-cover rounded-2xl overflow-hidden group",
				"border-4",
				"md:border-0",
				className
			) }
			style={ { ...style, width, height } }
		>
			{
				!disableClick &&
                <div
                    className="absolute top-0 left-0 w-full bg-myPrimary-purple-300 bg-opacity-50 group-hover:opacity-100 opacity-0 z-[1] flex items-center justify-center cursor-pointer transition-opacity"
                    style={ { height: "calc(100% - 30px)" } }
                >
                    <HiPlus className="h-16 w-16 text-myPrimary-orange-400"/>
                </div>
			}
			{
				isNotNull(image)
					?
					<Avatar
						img={ image?.thumb }
						className="absolute h-full w-full top-0 left-0 [&>div]:h-full [&>div]:w-full [&>div>img]:w-full [&>div>img]:h-full [&>div>img]:object-cover"
					/>
					:
					<PlaceholderImage
						className="absolute h-[116px] w-full top-0 left-0"
						style={ { paddingBottom: 20 } }
					/>
			}
			<div
				className="text-myPrimary-gray-500 bg-myPrimary-purple-500 bg-opacity-80 w-full flex items-center z-10 px-2 gap-1"
				style={ { height: 30 } }
			>
				<div
					className="font-bold text-sm truncate max-w-full"
					style={ { width: width - 85 } }
				>
					{ name }
				</div>
				<div className="flex justify-center items-center gap-1 text-myPrimary-purple-500 bg-myPrimary-gray-500 rounded px-2">
					<span className="text-[12px] font-[900] whitespace-nowrap">
						{ `1 - ${ maxPeople }` }
					</span>
					<span className="text-[10px] font-semibold">
						os.
					</span>
				</div>
			</div>
		</div>
	);
}

export default RoomPreviewAdmin;

