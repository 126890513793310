/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { SimpleRoom } from "src/app/types/api/room.types";
import { AvailabilitiesTimeReduce, CalculateReservationPricePayload, DetailedReservation, UpdateReservationPayload } from "src/app/types/api/reservation.types";
import { Product } from "src/app/types/api/product.types";
import { DiscountCode, DiscountCodeValidityType } from "src/app/types/api/discountCode.types";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { updateReservationFormActions } from "src/app/store/features/form/form.actions";
import { getAllProductsCount, getRoomReservationDiscountCodeOptions, getRoomReservationVoucherOptions, handleRoomReservationDiscountCodeChange, onRoomReservationProductMinus, onRoomReservationProductPlus, UpdateRoomReservationForm } from "src/app/utils/constants/roomReservation.form";
import React, { useEffect } from "react";
import { createFormField } from "src/app/utils/forms";
import { Button, Checkbox, Label, Modal } from "flowbite-react";
import { DateTime } from "luxon";
import Select from "src/app/components/Form/Select.component";
import Input from "src/app/components/Form/Input.component";
import PhoneInput from "src/app/components/Form/PhoneInput.component";
import { PulseLoader } from "react-spinners";
import classNames from "classnames";
import { Nullable, SelectOption } from "src/app/types/util.types";
import { LocaleFromISO } from "src/app/utils/luxon";
import { formatPhoneToE164, getVenueTodayDateJS } from "src/app/utils/helpers";
import DatePicker from "src/app/components/Form/DatePicker.component";
import { MAX_FUTURE_MONTHS } from "src/app/utils/constants/constants";
import { FormItem } from "src/app/types/ui/form.types";
import TextArea from "src/app/components/Form/TextArea.component";
import ChooseReservationStatus from "src/app/components/Form/ChooseReservationStatus/ChooseReservationStatus.component";
import ProductListComponent from "src/app/components/Product/ProductList.component";
import CounterCell from "src/app/components/Utils/CounterCell.component";
import { RootState } from "src/app/store/root.reducer";
import { getAdminRoomAvailabilities } from "src/app/store/features/reservation/reservation.selectors";
import { connect } from "react-redux";
import { DataState, LoadingState } from "src/app/types/redux.types";
import { SimpleVoucher } from "src/app/types/api/voucher.types";
import SelectDiscountCodeOrVoucher from "src/app/components/Room/Reservation/SelectDiscountCodeOrVoucher.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		date: DateTime<boolean>
		changeDate: (date: DateTime<boolean>) => void
		reservation: DetailedReservation
		handleClose: () => void
		room: SimpleRoom
		products: Product[]
		discountCodes: DiscountCode[]
		vouchers: SimpleVoucher[]
		onUpdate: (payload: UpdateReservationPayload) => void
		onCalculatePrice: (payload: CalculateReservationPricePayload) => void
		isOpen: boolean
		isCalculatingPrice: boolean
		toggleDeleteReservationModalOpen: (state: boolean) => void
		isDeleting: boolean
	}

function UpdateReservationModalInner(props: Props) {

	const {
		date,
		changeDate,
		reservation,
		handleClose,
		room,
		getRoomAvailabilities,
		products,
		discountCodes,
		vouchers,
		onUpdate,
		onCalculatePrice,
		isOpen,
		isCalculatingPrice,
		toggleDeleteReservationModalOpen,
		isDeleting,
	} = props;

	const roomAvailabilities = getRoomAvailabilities({ roomId: room.id, date: date });

	const _handleSubmit = (values: UpdateRoomReservationForm) => {
		if (
			isNull(values.startDate) ||
			isNull(values.endDate) ||
			isNull(values.status)
		) {
			return;
		}

		if (values.isForCompany) {
			onUpdate({
				id: reservation.id,
				roomId: room.id,
				startDate: values.startDate,
				endDate: values.endDate,
				people: +values.people,
				name: values.name,
				surname: values.surname,
				email: values.email,
				phone: values.formattedPhone,
				reservingPerson: values.reservingPerson,
				comment: values.comment,
				clientComment: values.clientComment,
				isMarketingConsent: values.isMarketingConsent,
				discountCode: values.discountCode ?? undefined,
				products: values.products,
				isForCompany: true,
				companyName: values.companyName,
				companyNip: values.companyNip,
				companyAddress: values.companyAddress,
				companyPostCode: values.companyPostCode,
				companyCity: values.companyCity,
				status: values.status,
			});
		} else {
			onUpdate({
				id: reservation.id,
				roomId: room.id,
				startDate: values.startDate,
				endDate: values.endDate,
				people: +values.people,
				name: values.name,
				surname: values.surname,
				email: values.email,
				phone: values.formattedPhone,
				reservingPerson: values.reservingPerson,
				comment: values.comment,
				clientComment: values.clientComment,
				isMarketingConsent: values.isMarketingConsent,
				discountCode: values.discountCode ?? undefined,
				products: values.products,
				isForCompany: false,
				status: values.status,
			});
		}
		handleClose();
	};

	const updateReservationForm = useReducerForm(
		"updateRoomReservation",
		updateReservationFormActions,
		_handleSubmit,
	);

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = updateReservationForm;

	const allProductsCount = getAllProductsCount(form.products.value);

	useEffect(() => {
		if (!isOpen) return;
		setForm({
			date: createFormField(date.toISO() ?? ""),
			startDate: createFormField(reservation.startDate),
			endDate: createFormField(reservation.endDate),
			people: createFormField(reservation.people.toString()),
			maxPeople: createFormField(room.maxPeople),
			name: createFormField(reservation.name),
			surname: createFormField(reservation.surname),
			email: createFormField(reservation.email),
			phone: createFormField(formatPhoneToE164(reservation.phone)),
			reservingPerson: createFormField(reservation.reservingPerson ?? "", { optional: true }),
			comment: createFormField(reservation.comment ?? "", { optional: true }),
			clientComment: createFormField(reservation.clientComment ?? "", { optional: true }),
			formattedPhone: createFormField(formatPhoneToE164(reservation.phone)),
			isMarketingConsent: createFormField(reservation.isMarketingConsent),
			products: createFormField(products.map(product => {
				const reservationProductQuantity = reservation.products?.find(({ id }) => id === product.id)?.quantity;
				return {
					id: product.id,
					quantity: reservationProductQuantity ?? 0,
				};
			})),
			discountCode: createFormField(reservation.discountCode?.code ?? null),
			isDiscountedPriceUnderflow: createFormField(false),
			isForCompany: createFormField(reservation.isForCompany),
			companyName: createFormField(reservation.isForCompany ? reservation.companyName : ""),
			companyNip: createFormField(reservation.isForCompany ? reservation.companyNip : ""),
			companyAddress: createFormField(reservation.isForCompany ? reservation.companyAddress : ""),
			companyPostCode: createFormField(reservation.isForCompany ? reservation.companyPostCode : ""),
			companyCity: createFormField(reservation.isForCompany ? reservation.companyCity : ""),
			status: createFormField(reservation.status),
			price: createFormField(reservation.price),
			discountedPrice: createFormField(null),
		});
	}, [ isOpen ]);

	// Calculating the price
	useEffect(() => {
		if (isNull(form.startDate.value) || isNull(form.endDate.value)) return;
		onCalculatePrice({
			reservationId: reservation.id,
			discountCode: form.discountCode?.value ?? undefined,
			products: form.products.value,
			roomId: room.id,
			startDate: form.startDate.value,
			endDate: form.endDate.value,
		});
	}, [ form.discountCode.value, allProductsCount, form.startDate.value, form.endDate.value ]);

	const getStartDateOptions = (): SelectOption<string>[] =>
		roomAvailabilities.dataState === DataState.PRESENT
			?
			roomAvailabilities.data
							  .filter(availability => (
								  availability.reservations.some(({ id }) => id === reservation.id) ||
								  !availability.isReserved
							  ))
							  .sort((a, b) => LocaleFromISO(a.startTime).valueOf() - LocaleFromISO(b.startTime).valueOf())
							  .reduce<AvailabilitiesTimeReduce>((prev, next) => {
								  if (isNull(form.endDate.value)) {
									  return {
										  ...prev,
										  availabilities: [ ...prev.availabilities, next ],
									  };
								  }

								  const isFinalArray = +DateTime.fromISO(next.endTime) === +DateTime.fromISO(form.endDate.value);
								  if (prev.availabilities.some(availability => +DateTime.fromISO(availability.endTime) <= +DateTime.fromISO(next.startTime))) {
									  return {
										  isFinalArray: prev.isFinalArray ? true : isFinalArray,
										  availabilities: [ ...prev.availabilities, next ],
									  };
								  } else if (!prev.isFinalArray) {
									  return {
										  isFinalArray: prev.isFinalArray ? true : isFinalArray,
										  availabilities: [ next ],
									  };
								  } else {
									  return prev;
								  }
							  }, {
								  isFinalArray: false,
								  availabilities: [],
							  })
							  .availabilities
							  .filter((startTime, index, arr) => arr.indexOf(startTime) === index)
							  .filter(availability => isNull(form.endDate.value) || LocaleFromISO(availability.startTime).valueOf() < LocaleFromISO(form.endDate.value).valueOf()) //lower than form.endDate.value
							  .map(availability => ({
								  label: LocaleFromISO(availability.startTime).toLocaleString(DateTime.TIME_24_SIMPLE),
								  value: availability.startTime,
							  }))
			:
			[];

	const getEndDateOptions = (): SelectOption<string>[] =>
		roomAvailabilities.dataState === DataState.PRESENT
			?
			roomAvailabilities.data
							  .filter(availability => (
								  availability.reservations.some(({ id }) => id === reservation.id) ||
								  !availability.isReserved
							  ))
							  .sort((a, b) => LocaleFromISO(a.endTime).valueOf() - LocaleFromISO(b.endTime).valueOf())
							  .reduce<AvailabilitiesTimeReduce>((prev, next) => {
								  if (isNull(form.startDate.value)) {
									  return {
										  ...prev,
										  availabilities: [ ...prev.availabilities, next ],
									  };
								  }

								  const isFinalArray = +DateTime.fromISO(next.startTime) === +DateTime.fromISO(form.startDate.value);
								  if (prev.availabilities.some(availability => +DateTime.fromISO(availability.endTime) <= +DateTime.fromISO(next.startTime))) {
									  return {
										  isFinalArray: prev.isFinalArray ? true : isFinalArray,
										  availabilities: [ ...prev.availabilities, next ],
									  };
								  } else if (!prev.isFinalArray) {
									  return {
										  isFinalArray: prev.isFinalArray ? true : isFinalArray,
										  availabilities: [ next ],
									  };
								  } else {
									  return prev;
								  }
							  }, {
								  isFinalArray: false,
								  availabilities: [],
							  })
							  .availabilities
							  .filter(availability => isNull(form.startDate.value) || LocaleFromISO(availability.endTime).valueOf() > LocaleFromISO(form.startDate.value).valueOf())
							  .filter((endTime, index, arr) => arr.indexOf(endTime) === index)
							  .map(availability => ({
								  label: LocaleFromISO(availability.endTime).toLocaleString(DateTime.TIME_24_SIMPLE),
								  value: availability.endTime,
							  }))
			:
			[];

	const createFormFieldWithAllValues = (formItem: FormItem<Nullable<string>>, newValue: Nullable<Date>): FormItem<Nullable<Date>> => ({
		value: newValue,
		initialValue: new Date(),
		touched: form.date.touched,
		error: form.date.error,
		success: form.date.success,
		disabled: form.date.disabled,
		optional: form.date.optional,
	});

	const handleSelectOnChange = (option: Nullable<SelectOption<string | null | undefined>>) => {
		handleRoomReservationDiscountCodeChange(updateReservationForm, option?.value);
	};

	const getDiscountCodesOptions = () => {

		// if current reservation has a discount code, include it in discount code options
		if (isNotNull(reservation.discountCode) && reservation.discountCode.validityType !== DiscountCodeValidityType.VOUCHER) {
			const reservationDiscountCode = reservation.discountCode;
			const filteredDiscountCodes = discountCodes.filter(code => code.id !== reservationDiscountCode.id);
			const activeDiscountCodesOptions = getRoomReservationDiscountCodeOptions(filteredDiscountCodes);
			return [ ...activeDiscountCodesOptions, { label: reservationDiscountCode.code, value: reservationDiscountCode.code } ];
		} else {
			return getRoomReservationDiscountCodeOptions(discountCodes);
		}
	};

	const getVouchersOptions = () => {

		// if current reservation has a voucher, include it in voucher options
		if (isNotNull(reservation.discountCode) && reservation.discountCode.validityType === DiscountCodeValidityType.VOUCHER) {
			return [ ...getRoomReservationVoucherOptions(vouchers), { label: reservation.discountCode.code, value: reservation.discountCode.code } ];
		} else {
			return getRoomReservationVoucherOptions(vouchers);
		}
	};

	const minDate = getVenueTodayDateJS();
	const maxDate = getVenueTodayDateJS();
	maxDate.setMonth(maxDate.getMonth() + MAX_FUTURE_MONTHS);

	return (
		<>
			<Modal.Header>
				{ `Rezerwacja ${ LocaleFromISO(reservation.startDate).toLocaleString(DateTime.DATETIME_MED) } - ${ LocaleFromISO(reservation.endDate).toLocaleString(DateTime.DATETIME_MED) }` }
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					<div className="space-y-3">
						{
							isNotNull(form.status.value) &&
                            <ChooseReservationStatus
                                status={ form.status.value }
                                onChange={ status => handleChange("status", status) }
                            />
						}
						<DatePicker
							label="Data (doba robocza)"
							formItem={
								isEmptyString(form.date.value) ?
									createFormFieldWithAllValues(form.date, null) :
									createFormFieldWithAllValues(form.date, LocaleFromISO(form.date.value).toJSDate())
							}
							onChange={ newDate => {
								if (DateTime.fromJSDate(newDate).day === DateTime.fromISO(form.date.value).day) {
									return;
								}
								handleChange("date", newDate.toISOString());
								handleChange("startDate", null);
								handleChange("endDate", null);
								changeDate(DateTime.fromJSDate(newDate));
							} }
							onBlur={ () => handleBlur("date") }
							minDate={ minDate }
							maxDate={ maxDate }
							showTimeInput={ false }
						/>
						<div className="grid grid-cols-2 gap-2">
							<div>
								<Select
									label="Czas rozpoczęcia"
									options={ getStartDateOptions() }
									formItem={ form.startDate }
									onChange={ option => {
										handleChange("startDate", option?.value ?? null);
										handleBlur("startDate");
									} }
									isSearchable={ false }
									isClearable
									isLoading={ roomAvailabilities.loadingState === LoadingState.LOADING }
								/>
							</div>
							<div>
								<Select
									label="Czas zakończenia"
									options={ getEndDateOptions() }
									formItem={ form.endDate }
									onChange={ option => {
										handleChange("endDate", option?.value ?? null);
										handleBlur("endDate");
									} }
									isSearchable={ false }
									isClearable
									isLoading={ roomAvailabilities.loadingState === LoadingState.LOADING }
								/>
							</div>
						</div>
						<Input
							formItem={ form.people }
							label="Ilość osób"
							name="people"
							inputProps={ {
								type: "number",
								onChange: (e) => handleChange("people", e.target.value),
								onBlur: () => handleBlur("people"),
								max: room.maxPeople,
							} }
						/>
						<div className="grid grid-cols-2 gap-2">
							<Input
								formItem={ form.name }
								label="Imię"
								name="name"
								inputProps={ {
									type: "text",
									onChange: (e) => handleChange("name", e.target.value),
									onBlur: () => handleBlur("name"),
								} }
							/>
							<Input
								formItem={ form.surname }
								label="Nazwisko"
								name="surname"
								inputProps={ {
									type: "text",
									onChange: (e) => handleChange("surname", e.target.value),
									onBlur: () => handleBlur("surname"),
								} }
							/>
						</div>
						<div className="grid grid-cols-2 gap-2">
							<Input
								formItem={ form.email }
								label="Email"
								name="email"
								inputProps={ {
									type: "email",
									onChange: (e) => handleChange("email", e.target.value),
									onBlur: () => handleBlur("email"),
								} }
							/>
							<PhoneInput
								label="Numer telefonu"
								name="phone"
								phoneInputProps={ {
									country: "pl",
									onEnterKeyPress: () => handleSubmit(),
								} }
								formItem={ form.phone }
								onChange={ (phone, formattedPhone) => {
									handleChange("phone", phone);
									handleChange("formattedPhone", formattedPhone);
								} }
								onBlur={ () => handleBlur("phone") }
							/>
						</div>
						<div
							className="flex items-center gap-x-3"
							onClick={ () => handleChange("isMarketingConsent", !form.isMarketingConsent.value) }
						>
							<Checkbox
								name="isMarketingConsent"
								checked={ form.isMarketingConsent.value }
								onChange={ () => undefined }
							/>
							<Label htmlFor="isMarketingConsent">
								Zgoda marketingowa
							</Label>
						</div>
						<div
							className="flex items-center gap-x-3"
							onClick={ () => handleChange("isForCompany", !form.isForCompany.value) }
						>
							<Checkbox
								name="isForCompany"
								checked={ form.isForCompany.value }
								onChange={ () => undefined }
							/>
							<Label htmlFor="isForCompany">
								Dane firmy
							</Label>
						</div>
						{
							form.isForCompany.value &&
                            <div className="space-y-3">
                                <div className="grid grid-cols-2 gap-2">
                                    <Input
                                        formItem={ form.companyName }
                                        label="Nazwa firmy"
                                        name="companyName"
                                        inputProps={ {
											type: "text",
											onChange: (e) => handleChange("companyName", e.target.value),
											onBlur: () => handleBlur("companyName"),
										} }
                                    />
                                    <Input
                                        formItem={ form.companyNip }
                                        label="NIP"
                                        name="companyNip"
                                        inputProps={ {
											type: "text",
											onChange: (e) => handleChange("companyNip", e.target.value),
											onBlur: () => handleBlur("companyNip"),
										} }
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-2">
                                    <Input
                                        formItem={ form.companyAddress }
                                        label="Adres"
                                        name="companyAddress"
                                        inputProps={ {
											type: "text",
											onChange: (e) => handleChange("companyAddress", e.target.value),
											onBlur: () => handleBlur("companyAddress"),
										} }
                                    />
                                    <Input
                                        formItem={ form.companyPostCode }
                                        label="Kod pocztowy"
                                        name="companyPostCode"
                                        inputProps={ {
											type: "text",
											onChange: (e) => handleChange("companyPostCode", e.target.value),
											onBlur: () => handleBlur("companyPostCode"),
										} }
                                    />
                                </div>
                                <Input
                                    formItem={ form.companyCity }
                                    label="Miasto"
                                    name="companyCity"
                                    inputProps={ {
										type: "text",
										onChange: (e) => handleChange("companyCity", e.target.value),
										onBlur: () => handleBlur("companyCity"),
									} }
                                />
                            </div>
						}
						<ProductListComponent
							products={ products }
							disableClick={ true }
							rowsPerPage={ 5 }
							actionsCell={ product => {
								const formProduct = form.products.value.find(({ id }) => id === product.id);
								if (isNull(formProduct)) return;
								return (
									<CounterCell
										onProductPlus={ productId => onRoomReservationProductPlus(updateReservationForm, productId) }
										onProductMinus={ productId => onRoomReservationProductMinus(updateReservationForm, productId) }
										formProduct={ formProduct }
									/>
								);
							} }
						/>
						<SelectDiscountCodeOrVoucher
							discountCodesOptions={ getDiscountCodesOptions() }
							vouchersOptions={ getVouchersOptions() }
							formItem={ form.discountCode }
							onChange={ handleSelectOnChange }
						/>
						<Input
							formItem={ form.reservingPerson }
							label="Kto rezerwuje"
							name="reservingPerson"
							inputProps={ {
								type: "text",
								onChange: e => handleChange("reservingPerson", e.target.value),
								onBlur: () => handleBlur("reservingPerson"),
							} }
						/>
						<TextArea
							label="Komentarz obsługi"
							formItem={ form.comment }
							name="createReservationFormComment"
							inputProps={ {
								onChange: e => handleChange("comment", e.target.value),
								onBlur: () => handleBlur("comment"),
							} }
						/>
						<TextArea
							label="Komentarz klienta"
							formItem={ form.clientComment }
							name="createReservationFormClientComment"
							inputProps={ {
								onChange: e => handleChange("clientComment", e.target.value),
								onBlur: () => handleBlur("clientComment"),
							} }
						/>
						<div className="flex gap-2 items-end mx-auto w-max">
							<div className="text-myPrimary-purple-400 text-2xl font-semibold mr-2">
								Cena:
							</div>
							<div className="flex items-end gap-2 relative w-max">
								{
									isCalculatingPrice &&
                                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex">
                                        <PulseLoader color="#1b000b" size={ 10 }/>
                                    </div>
								}
								<div className={ classNames(
									"text-myPrimary-purple-400 text-5xl font-[900]",
									{ "opacity-50 line-through": isNotNull(form.discountedPrice.value) },
									{ "opacity-50": isCalculatingPrice },
								) }>
									{ form.price.value || 0 }
								</div>
								{
									isNotNull(form.discountedPrice.value) &&
                                    <div className="text-myPrimary-purple-300 text-2xl font-semibold">
										{
											form.isDiscountedPriceUnderflow.value
												?
												"0"
												:
												form.discountedPrice.value
										}
                                    </div>
								}
							</div>
							<div className="text-myPrimary-purple-300 text-2xl font-semibold">
								zł
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<div className="flex items-center gap-2">
						<Button onClick={ handleClose } color="gray-outline">
							Anuluj
						</Button>
						<Button
							onClick={ () => toggleDeleteReservationModalOpen(true) }
							color="failure-outline"
							isProcessing={ isDeleting }
						>
							Usuń
						</Button>
					</div>
					<Button
						type="submit"
						disabled={ isCalculatingPrice }
					>
						Zapisz
					</Button>
				</Modal.Footer>
			</form>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	getRoomAvailabilities: ({ roomId, date }: { roomId: number, date: DateTime }) => getAdminRoomAvailabilities(state, { roomId, date }),
});

export default connect(mapStateToProps)(UpdateReservationModalInner);
