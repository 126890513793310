import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store, { history } from "src/app/store/index";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "src/assets/styles/index.scss";
import PopupsWrapper from "src/app/hoc/wrappers/PopupsWrapper.hoc";
import AppErrorBoundary from "src/app/hoc/boundaries/AppError.boundary";
import { HistoryRouter } from "redux-first-history/rr6";
import App from "src/app/app";
import RouterMapper from "src/app/hoc/routes/RouterMapper.component";
import { appRoutes } from "src/app/utils/routes/app.routes";
import { Flowbite } from "flowbite-react";
import appTheme from "src/app/utils/constants/theme";
import SidebarProvider from "src/app/hoc/providers/Sidebar.provider";
import { Suspense } from "react";
import { Settings } from "luxon";
import moment from "moment";
import "moment/locale/pl";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import TagManager from "react-gtm-module";
import { isEmptyString } from "src/app/utils/typeguards";
import { getEnvValue } from "src/app/utils/constants/constants";
import MetaPixel from "src/app/components/Utils/MetaPixel.component";
import HotJar from "src/app/components/Utils/HotJar.component";

Settings.defaultLocale = "pl";
moment().locale("pl");
registerLocale("pl", pl);

const persistor = persistStore(store);

const root = createRoot(document.getElementById("root") as HTMLElement);

!isEmptyString(getEnvValue("REACT_APP_GTM_ID")) && TagManager.initialize({ gtmId: getEnvValue("REACT_APP_GTM_ID") });

root.render(
	<Flowbite theme={ { theme: appTheme } }>
		<Provider store={ store }>
			<HistoryRouter history={ history }>
				<PersistGate persistor={ persistor } loading={ <div>Loading ...</div> }>
					<PopupsWrapper>
						<AppErrorBoundary>
							<SidebarProvider>
								<HotJar/>
								<MetaPixel/>
								<App>
									<Suspense>
										<RouterMapper routes={ appRoutes }/>
									</Suspense>
								</App>
							</SidebarProvider>
						</AppErrorBoundary>
					</PopupsWrapper>
				</PersistGate>
			</HistoryRouter>
		</Provider>
	</Flowbite>,
);
